/**
 * Represents a FileAttachmentRequest object used for managing files.
 *
 * @class FileAttachmentRequest
 */
export class FileAttachmentRequest {
	/**
	 * The taregt id.
	 * @type {string | undefined}
	 */
	Target;

	/**
	 * The Source id of the odoo object.
	 * @type {string | undefined}
	 */
	Source;

	/**
	 * The Unique Source of the odoo object.
	 * @type {string | undefined}
	 */
	UniqueSource;


	/**
	 * The string representing the child JSON data.
	 * @type {string | undefined}
	 */
	SourceModel;

	
	/**
	 * Creates a new FileItem object.
	 *
	 * @param {string | undefined} [Target] - The target id.
	 * @param {string | undefined} [Source] - The source id.
	 * @param {string | undefined} [UniqueSource] - The unique source id of the object.
	 * @param {string | undefined} [SourceModel] - The underlying odoo model.
	 */
	constructor(
		Target?: string,
		Source?: string,
		UniqueSource?: string,
		SourceModel?: string,
	) {
		this.Target = Target;
		this.Source = Source;
		this.UniqueSource = UniqueSource;
		this.SourceModel = SourceModel;
	}
}
