import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DragEventArgs, ListBoxChangeEventArgs, ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { useRef, useState } from 'react';
import { fileMergePDFService } from '../../apis/fileService';
import { FileItem } from '../../models/FileItem';
import { FileSession } from '../../models/FileSession';

interface Props {
    files: FileItem[];
    setOpen: (value: boolean) => void;
    fileProvider: string;
    fileSession: FileSession;
}
export default function FileManagerMergePdfFiles({ files, setOpen, fileProvider, fileSession }: Props) {

    const listboxRef = useRef<ListBoxComponent>(null);
    const [currentName, setCurrentName] = useState<string>('');
    const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);
    debugger;
    //Saves the merge changes 
    const handleMergeChanges = () => {

        async function doAsyncFunction() {
            try {
                if (currentName && currentName.length > 0) {
                    debugger;
                    setIsSavingChanges(true);
                    var currentSelectedFiles: FileItem[] = [];
                    var listItems = listboxRef!.current!.getItems();
                    listItems.forEach((a) => {
                        let newItem = new FileItem();
                        newItem.name = a.textContent ?? "";
                        newItem.path = a.getAttribute('data-value') ?? "";
                        newItem.order = currentSelectedFiles?.length;
                        currentSelectedFiles.push(newItem);
                        //console.log("item is " + newItem.name + " order is " + newItem.order);
                    });

                    const result = await fileMergePDFService(currentSelectedFiles, currentName, fileProvider, fileSession);
                    if (result.data) {
                        if (result.data.Success) {
                            setIsSavingChanges(false);
                            setOpen(false);

                        }
                        else {
                            alert("An error occurred");
                            setIsSavingChanges(false);
                        }
                    }
                    else {
                        setIsSavingChanges(false);
                    }
                }
                else {
                    showToast("file name is required", "Merge Files");
                }

            } catch (e) {
            }
        }
        doAsyncFunction().then(
            () => {
                //setOpen(false);
            }
        );
    }

    const handleFileNameChange = (e: any) => {
        //setFileName(e.target.value);
        setCurrentName(e.target.value);
    }

    const onSelectionChanged = (args: ListBoxChangeEventArgs) => {
        var currentN = args?.items?.at(0) as FileItem;
        var fileName = currentN.name;
        if (fileName) {
            var fileExt = fileName.substring(0, fileName.lastIndexOf('.'));
            setCurrentName(fileExt);
        }
    }

    const onDrop = (args: DragEventArgs) => {
        var currentSelectedFiles: FileItem[] = [];
        var listItems = listboxRef!.current!.getItems();
        listItems.forEach((a) => {
            let newItem = new FileItem();
            newItem.name = a.textContent ?? "";
            newItem.path = a.getAttribute('data-value') ?? "";
            newItem.order = currentSelectedFiles?.length;
            currentSelectedFiles.push(newItem);
        });

    }

    const dropDownFields: object = { text: 'name', value: 'path' };

    const showToast = (message: string, title: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 5000,
            cssClass: 'e-toast-warning'
        });
    }

    return (
        <div style={{ background: 'white' }} >
            <div className="e-control e-toolbar e-lib e-keyboard e-toolpop"
                style={{ height: 'auto', display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                <div className="e-toolbar-items e-tbar-pos" style={{ height: '42px' }}>

                    <div className="e-toolbar-item">
                        <ButtonComponent
                            disabled={isSavingChanges}
                            iconCss='e-icons e-save-changes'
                            className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                            title="Apply Split Changes"
                            iconPosition='Left'
                            onClick={handleMergeChanges}
                        >Apply Merge Changes
                        </ButtonComponent>
                    </div>
                </div>
            </div>

            <br></br>

            <div className="e-card">
                <div style={{
                    padding: '5px',
                    marginRight: '10px'
                }} >
                    <input className="e-input"
                        type="text"
                        value={currentName}
                        onChange={handleFileNameChange}
                        placeholder="Merged File Name" />
                </div>

                <div style={{ padding: '5px' }}>
                    <ListBoxComponent ref={listboxRef}
                        sortOrder="None"
                        className="e-row"
                        height="22vh"
                        style={{ overflowY: 'auto' }}
                        change={(e) => onSelectionChanged(e)}
                        dataSource={files as any}
                        fields={dropDownFields}
                        allowDragAndDrop={true} drop={(e) => onDrop(e)} />
                </div>
            </div>
        </div>
    )
}
