/**
 * Represents a ConnectedTargetItemsRequest object used for fetching file connections.
 *
 * @class ConnectedTargetItemsRequest
 */
export class ConnectedSourceItemsRequest {

	/**
	 * The Source id of the odoo object.
	 * @type {string | undefined}
	 */
	SourceId;
	
	/**
	 * Creates a new FileItem object.
	 *
	 * @param {string | undefined} [SourceId] - The source id.
	 */
	constructor(
		SourceId?: string,
		
	) {
		this.SourceId = SourceId;
		
	}
}
