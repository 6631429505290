import { ToastUtility } from '@syncfusion/ej2-notifications';
import { BeforeSaveEventArgs, SaveCompleteEventArgs, SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenService } from '../../apis/fileService';
import { SPREADSHEET_SERVICE_URL, SPREADSHEET_SERVICE_URL_SAVE } from '../../environment/env_dev';
import { FileSession } from '../../models/FileSession';
import "../FileManager/FileManagerSpreadsheetEditor.scss";
import NavBar from '../NavBar';

interface Props {
    fileSession: FileSession;
}
export default function FileManagerSpreadsheetEditor({ fileSession }: Props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const filePath = queryParams.get('path') ?? "";
    const fileName = queryParams.get('name') ?? "";
    const fileProvider = queryParams.get('provider') ?? "";
    const spreadsheetComponent = useRef<SpreadsheetComponent>(null);
    const [base64FileString, setBase64FileString] = useState<string>();
    let mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";



    useEffect(() => {
        async function loadDocumentFromServer() {

            if (base64FileString) {
                var file = base64ToFile(base64FileString, fileName, mimeType);
                spreadsheetComponent?.current?.open({ file: file });
            }
        }

        loadDocumentFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64FileString]);



    useEffect(() => {
        async function loadFileFromServer() {
            try {
                // make a fetch request to the action method URL
                const result = await fileOpenService(filePath, fileName, fileProvider ?? "", fileSession);
                var data = result?.data?.Data;
                if (data) {
                    setBase64FileString(data.FileBase64String);
                    showToast("Please wait...", "Loading Document", "e-toast-info");
                }
            } catch (error) {
                // handle any errors that may occur
                console.log('There has been a problem with your fetch operation: ', error);
            }
        }

        loadFileFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 4000,
            cssClass: cssClass,

        });
    }

    const base64ToFile = (base64: any, filename: any, mimeType: any): File => {
        // remove the prefix that indicates the file type
        var base64Data = base64.split(';base64,').pop();

        // decode the base64 string
        var fileBuffer = atob(base64Data);

        // create an ArrayBuffer and a view (as unsigned 8-bit)
        var buffer = new ArrayBuffer(fileBuffer.length);
        var view = new Uint8Array(buffer);

        // fill the view, using the decoded base64
        for (var i = 0; i < fileBuffer.length; i++) {
            view[i] = fileBuffer.charCodeAt(i);
        }

        // create a file object with the file data, a name, and a MIME type
        var file = new File([buffer], filename, { type: mimeType });

        // return the file object
        return file;
    };

    const saveChanges = () => {
        if (spreadsheetComponent && spreadsheetComponent.current) {
            const fileExtension = fileName.split('.').pop()?.toLowerCase();

            let saveOptions: any = { saveType: 'Xlsx', fileName: fileName }; // Default save options

            if (fileExtension === 'csv') {
                saveOptions = { saveType: 'Csv', fileName: fileName };
            } else if (fileExtension === 'xls') {
                saveOptions = { saveType: 'Xls', fileName: fileName };
            } else if (fileExtension === 'xlsb') {
                saveOptions = { saveType: 'Xlsb', fileName: fileName };
            } else if (fileExtension === 'ods') {
                saveOptions = { saveType: 'Ods', fileName: fileName };
            }

            spreadsheetComponent.current.save(saveOptions);
            showToast("Please wait...", "Saving Document", "e-toast-info");
        }
    }



    const saveChange = () => {
        if (spreadsheetComponent && spreadsheetComponent.current) {
            spreadsheetComponent.current.saveAsJson().then((response: any) => {
                let formData = new FormData();
                // Parse the JSON string to an object
                let jsonObject = JSON.parse(JSON.stringify(response.jsonObject.Workbook));
                formData.append('JSONData', JSON.stringify(jsonObject));

                // Determine the file extension from the fileName
                const fileExtension = fileName.split('.').pop()?.toLowerCase();

                let saveType = 'Xlsx'; // Default save type
                if (fileExtension === 'csv') {
                    saveType = 'Csv';
                } else if (fileExtension === 'xls') {
                    saveType = 'Xls';
                } else if (fileExtension === 'xlsb') {
                    saveType = 'Xlsb';
                } else if (fileExtension === 'ods') {
                    saveType = 'Ods';
                }

                formData.append('fileName', fileName);
                formData.append('saveType', saveType);
                formData.append('pdfLayoutSettings', 'landscape');
                formData.append('saveUrl', SPREADSHEET_SERVICE_URL_SAVE);

                // Ensure the session values are defined
                if (fileSession.sessionId && fileSession.sessionHost && fileSession.sessionUid) {
                    fetch(
                        `${SPREADSHEET_SERVICE_URL_SAVE}?path=${getFilePath()}`,
                        {
                            method: 'POST',
                            headers: {
                                'session-id': fileSession.sessionId,
                                'session-host': fileSession.sessionHost,
                                'session-uid': fileSession.sessionUid,
                                'session-b2c': fileSession.sessionB2C ?? "",
                            },
                            body: formData,
                            mode: 'no-cors'
                        }
                    ).then((response) => {
                        showToast("Please wait...", "Saving Document", "e-toast-info");
                    });
                } else {
                    // Handle the case where session values are not defined
                    console.error('Session values are not defined');
                }
            });
        }
    }


    const onSaveComplete = (args: SaveCompleteEventArgs) => {
        //args.blobData
        console.log("Save complete"); 
    }

    const onBeforeSave = (args: BeforeSaveEventArgs) => {
        args.needBlobData = true;
        args.isFullPost = false;
    }

    const getFilePath = () => {
        return filePath;
    }

    return (
        <div style={{ height: '95vh', 'width': '100%' }}>
            <NavBar filePath={filePath} fileName={fileName} saveFunction={() => { saveChanges(); }
            } saveAsFunction={() => { }} cancelFunction={() => {
                    if (window.opener) {
                        window.close();
                    } else {
                        window.location.href = 'about:blank';
                        window.close();
                    }
                }}
                closeFunction={() => {
                    if (window.opener) {
                        window.close();
                    } else {
                        window.location.href = 'about:blank';
                        window.close();
                    }
                }} />
            <SpreadsheetComponent beforeSave={(e) => onBeforeSave(e)} saveComplete={(e) => onSaveComplete(e)} allowSave={true} showRibbon={true} ref={spreadsheetComponent} openUrl={SPREADSHEET_SERVICE_URL} saveUrl={`${SPREADSHEET_SERVICE_URL_SAVE}?path=${getFilePath()}&sessionId=${fileSession.sessionId}`}
            >

            </SpreadsheetComponent>
        </div>
    )
}
