/**
 * Represents a AttachedItem object used for managing files.
 *
 * @class AttachedItem
 */
export class AttachedItem {
	/**
	 * The unique identifier for the file.
	 * @type {string | undefined}
	 */
	id;

	/**
	 * The name of the file.
	 * @type {string | undefined}
	 */
	name;

	/**
	 * The path of the file.
	 * @type {string | undefined}
	 */
	path;


	/**
	 * The string representing the child JSON data.
	 * @type {string | undefined}
	 */
	childJson;

	/**
	 * The string representing the parent JSON data.
	 * @type {string | undefined}
	 */
	parentJson;

	/**
	* The base64-encoded string representing the parent JSON data.
	* @type {boolean | undefined}
	*/
	isAttached;

	/**
	 * Creates a new FileItem object.
	 *
	 * @param {string | undefined} [id] - The unique identifier for the file.
	 * @param {string | undefined} [name] - The name of the file.
	 * @param {string | undefined} [path] - The path of the file.
	 * @param {string | undefined} [childJson] - The base64-encoded string representing the child JSON data.
	 * @param {string | undefined} [parentJson] - The base64-encoded string representing the parent JSON data.
	 * @param {boolean | undefined} [isAttached] - The base64-encoded string representing the parent JSON data.
	 */
	constructor(
		id?: string,
		name?: string,
		path?: string,
		childJson?: string,
		parentJson?: string,
		isAttached?: boolean,
	) {
		this.id = id;
		this.name = name;
		this.path = path;
		this.childJson = childJson;
		this.parentJson = parentJson;
		this.isAttached = isAttached;
	}
}
