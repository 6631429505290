import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { createRef, useEffect, useRef, useState } from 'react';
import { FileSession } from '../../models/FileSession';
import NavBar from '../NavBar';
import { SPREADSHEET_SERVICE_URL } from '../../environment/env_dev';

interface Props {
    base64FileString?: string;
    fileName?: string;
    fileSession: FileSession;
}

const getMimeType = (fileExtension: any) => {
    switch (fileExtension) {
        case 'csv':
            return 'text/csv';
        case 'xls':
            return 'application/vnd.ms-excel';
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'xlsb':
            return 'application/vnd.ms-excel.sheet.binary.macroEnabled.12';
        case 'ods':
            return 'application/vnd.oasis.opendocument.spreadsheet';
        default:
            return null;
    }
};
export default function FileManagerSpreadsheetPreviewer({ base64FileString, fileName, fileSession }: Props) {
    const spreadsheetComponent = useRef<SpreadsheetComponent>(null);;
    //let mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";


    useEffect(() => {
        async function loadDocumentFromServer() {
            if (base64FileString && fileName) {
                const fileExtension = fileName.split('.').pop()?.toLowerCase();
                const mimeType = getMimeType(fileExtension);
                if (mimeType) {
                    const file = base64ToFile(base64FileString, fileName, mimeType);
                    spreadsheetComponent?.current?.open({ file: file });
                }
            }
        }

        loadDocumentFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64FileString]);


    const base64ToFile = (base64: any, filename: any, mimeType: any): File => {
        // remove the prefix that indicates the file type
        var base64Data = base64.split(';base64,').pop();

        // decode the base64 string
        var fileBuffer = atob(base64Data);

        // create an ArrayBuffer and a view (as unsigned 8-bit)
        var buffer = new ArrayBuffer(fileBuffer.length);
        var view = new Uint8Array(buffer);

        // fill the view, using the decoded base64
        for (var i = 0; i < fileBuffer.length; i++) {
            view[i] = fileBuffer.charCodeAt(i);
        }

        // create a file object with the file data, a name, and a MIME type
        var file = new File([buffer], filename, { type: mimeType });

        // return the file object
        return file;
    };

    return (
        <div style={{ height: '90vh' }}> <SpreadsheetComponent showRibbon={false} ref={spreadsheetComponent} openUrl={SPREADSHEET_SERVICE_URL} allowEditing={false}
        >

        </SpreadsheetComponent></div>
    )
}
