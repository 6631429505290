/**
 * Represents a FileItem object used for managing files.
 *
 * @class FileItem
 */
export class FileItem {
    /**
     * The unique identifier for the file.
     * @type {string | undefined}
     */
    id;

    /**
     * The name of the file.
     * @type {string | undefined}
     */
    name;

    /**
     * The base64-encoded string representing the file content.
     * @type {string | undefined}
     */
    base64String;

    /**
     * The path of the file.
     * @type {string | undefined}
     */
    path;

    /**
     * The order of the file.
     * @type {number | undefined}
     */
    order;

    /**
     * The base64-encoded string representing the child JSON data.
     * @type {string | undefined}
     */
    childJson;

    /**
     * The base64-encoded string representing the parent JSON data.
     * @type {string | undefined}
     */
    parentJson;

    /**
     * The unique identifier for the provider associated with the file.
     * @type {string | undefined}
     */
    providerId;

    /**
     * Creates a new FileItem object.
     *
     * @param {string | undefined} [id] - The unique identifier for the file.
     * @param {string | undefined} [name] - The name of the file.
     * @param {string | undefined} [base64String] - The base64-encoded string representing the file content.
     * @param {string | undefined} [path] - The path of the file.
     * @param {number | undefined} [order] - The order of the file.
     * @param {string | undefined} [childJson] - The base64-encoded string representing the child JSON data.
     * @param {string | undefined} [parentJson] - The base64-encoded string representing the parent JSON data.
     * @param {string | undefined} [providerId] - The unique identifier for the provider associated with the file.
     */
    constructor(
        id?: string,
        name?: string,
        base64String?: string,
        path?: string,
        order?: number,
        childJson?: string,
        parentJson?: string,
        providerId?: string
    ) {
        this.id = id;
        this.name = name;
        this.base64String = base64String;
        this.path = path;
        this.order = order;
        this.childJson = childJson;
        this.parentJson = parentJson;
        this.providerId = providerId;
    }
}
