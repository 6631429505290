import { ToastUtility } from '@syncfusion/ej2-notifications';
import * as pdfjs from "pdfjs-dist";
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenService } from '../../apis/fileService';
import { FilePageItem } from '../../models/FilePageItem';
import { FileSession } from '../../models/FileSession';
import "../FileManager/PdfEditorPage.scss";

interface Props {
    fileSession: FileSession;
    pdfPath: string;
    fileName: string;
    fileId: string;
    fileProvider: string;
}

export const PdfJsPreviewer = ({ fileSession, pdfPath, fileName, fileId, fileProvider }: Props) => {
    //const pdfjs = require('pdfjs-dist');
    const location = useLocation();



    const [saveSplitChanges, setSaveSplitChanges] = useState<boolean>(false);
    const [checked, setChecked] = React.useState<number[]>();
    const [gridHeight, setGridHeight] = React.useState<number>(200);
    const [sliderValue, setSliderValue] = React.useState<number>(0);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
    const [hoveredPageNumber, setHoveredPageNumber] = React.useState<number>(0);
    const [pdfPageCount, setPdfPageCount] = React.useState<number>(0);


    const sideThumbnailsRef = useRef<(HTMLDivElement | null)[]>([]);
    const mainThumbnailsRef = useRef<(HTMLDivElement | null)[]>([]);

    const [filePageItems, setFilePageItems] = React.useState<FilePageItem[]>([]);
    const displayedItems = filePageItems
        .filter(item => !item.Deleted)
        .sort((a, b) => a.PageNumber - b.PageNumber);
    var pdfjsLib: any;
    pdfjsLib = pdfjs;
    pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/build/pdf.worker.js";
    const [base64FileString, setBase64FileString] = useState<string>();
    const [past, setPast] = useState<FilePageItem[][]>([]);
    const [future, setFuture] = useState<FilePageItem[][]>([]);

    const editCommands = filePageItems.map(item => {
        let commandType = item.Deleted ? 'delete' : 'rotate';
        if (item.PageNumber !== item.OldPageNumber) {
            commandType = 'move';
        }
        return {
            Type: commandType,
            PageNumber: item.PageNumber,
            OldPageNumber: item.OldPageNumber,
            Degrees: item.Rotation,
        };
    });

    useEffect(() => {
        const broadcastChannel = new BroadcastChannel("files-channel");
        broadcastChannel.onmessage = (event) => {
            const { sessionId, host, uid } = event.data;

            localStorage.setItem('session-id', sessionId);
            localStorage.setItem('session-host', host);
            localStorage.setItem('session-uid', uid);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let emptyNumberArray: number[] = [];
        setSelectedIndex(0);
        setChecked(emptyNumberArray);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (selectedIndex && sideThumbnailsRef && sideThumbnailsRef.current) {
            try {
                let moveTo = (selectedIndex + 1);
                sideThumbnailsRef?.current[moveTo]?.scrollIntoView({ behavior: "smooth", block: "start" });
                mainThumbnailsRef?.current[moveTo]?.scrollIntoView({ behavior: "smooth", block: "start" });

            } catch (e) {
                console.log(e);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);



    useEffect(() => {
        if (base64FileString) {
            const loadingTask = pdfjs.getDocument(`data:application/pdf;base64,${base64FileString}`);
            loadingTask.promise.then(function (pdfDocument: any) {
                // Get number of pages
                const numPages = pdfDocument.numPages;
                setPdfPageCount(numPages);
                let tempArray: FilePageItem[] = [];

                // Loop through all pages
                for (let i = 1; i <= numPages; i++) {
                    // Get page i
                    pdfDocument.getPage(i).then(function (pdfPage: any) {
                        // Get viewport at scale 3
                        const viewport = pdfPage.getViewport({ scale: 4 });
                        // Create canvas element for page i
                        const canvas = document.createElement('canvas');
                        canvas.id = 'canvas' + i;
                        // Get canvas context
                        const context = canvas.getContext('2d');
                        // Set canvas dimensions to match viewport size
                        canvas.width = viewport.width;
                        canvas.height = viewport.height;
                        // Render page on canvas
                        pdfPage.render({
                            canvasContext: context,
                            viewport: viewport,
                        }).promise.then(function () {
                            // Create a new FilePageItem object with constructor parameters
                            let filePageItem = new FilePageItem(
                                i.toString(), // Id
                                base64FileString, // FileId
                                '', // ImageData (will be assigned later)
                                null, // PageData (will be assigned later)
                                fileName, // FileName
                                i, // PageNumber
                                numPages, // PageCount
                                viewport.width, // Width
                                viewport.height, // Height
                            );
                            // Get base64 string of image data and assign it to ImageData property
                            filePageItem.ImageData = canvas.toDataURL();
                            // Get blob data of canvas and assign it to PageData property using a callback function
                            canvas.toBlob(function (blob) {
                                filePageItem.PageData = blob;
                                // Push filePageItem to tempArray
                                tempArray.push(filePageItem);
                                // If tempArray length is equal to numPages, update state variable with tempArray
                                if (tempArray.length === numPages) {
                                    let sortedArray = tempArray.sort((a, b) => a.PageNumber - b.PageNumber)
                                    setFilePageItems(sortedArray);
                                }
                            });
                        });
                    });
                }
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [base64FileString]);



    useEffect(() => {
        async function loadFileFromServer() {
            try {
                // make a fetch request to the action method URL
                const result = await fileOpenService(pdfPath, fileName, fileProvider ?? "", fileSession);
                var data = result?.data?.Data;
                if (data) {
                    setBase64FileString(data.FileBase64String);

                }
            } catch (error) {
                // handle any errors that may occur
                console.log('There has been a problem with your fetch operation: ', error);
            }
        }

        loadFileFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileName]);


    //Checks or unchecks a page for spit function
    const handleToggle = (value: number) => () => {
        var checkeds = checked ?? [];

        const currentIndex = checkeds.indexOf(value);
        const newChecked = [...checkeds];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    //Checks whether a page is selected
    const isPageChecked = (value: number) => {
        if (checked) {
            return checked.indexOf(value) !== -1;
        }
        else {
            return false;
        }
    }

    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 4000,
            cssClass: cssClass,

        });
    }

    //check orientation of a thumbnail
    const getComputedDimension = (height: number, width: number, containerHeight: number, isHeight: boolean) => {
        if (isHeight) {
            return height * containerHeight / Math.max(height, width);
        } else {
            return width * containerHeight / Math.max(height, width);
        }
    }

    const handleMouseEnter = (pageNumber: number) => {
        setHoveredPageNumber(pageNumber);
    }

    const handleMouseLeave = (pageNumber: number) => {
        setHoveredPageNumber(0);
    }

    const canRearrange = (pageNumber: number) => {
        if (pageNumber === hoveredPageNumber) {
            return true;
        }
        return false;
    }

    return (
        <div id="pdf-previewer-page" style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', height: `calc(100vh - 80px)` }}>

            <div style={{ height: `calc(100vh - 100px)`, width: '100%', overflowY: 'auto', overflowX:'hidden' }}>
                <div
                    id="editor-content"
                    style=
                    {{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'inherit'
                    }}>

                    <div
                        style=
                        {{
                            height: '100%',
                            width: '100vw',
                            margin: '5px auto',

                        }}>
                        {
                            displayedItems.map((filePage) =>

                                <div className="e-card" key={filePage.Id.toString()}
                                    ref={(el) => (mainThumbnailsRef.current[filePage.PageNumber] = el!)}
                                    style=
                                    {{
                                        margin: '10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}

                                    onClick={() => {
                                        setSelectedIndex(filePage.PageNumber - 1);
                                    }}>

                                    <img
                                        id={`img-${Math.random().toString(36).slice(2, 7)}`}
                                        src={filePage.ImageData}
                                        alt={(filePage.PageNumber).toString()}
                                        style={{
                                            objectFit: 'contain',
                                            width: '80%',
                                            height: '100%',
                                            margin: '10px'
                                        }}
                                    />

                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    )
};