import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

interface Props {
    filePath: string;
    fileName: string;
    saveFunction: () => void;
    saveAsFunction: () => void;
    cancelFunction: any;
    closeFunction: any;
    showSaveAs?: boolean;
}

export default function NavBar({ fileName, filePath,showSaveAs ,saveFunction, saveAsFunction, cancelFunction, closeFunction }: Props) {

    const handleSaveFunction = () => {
        saveFunction();
    }

    const handleSaveAsFunction = () => {
        saveAsFunction();
    }

    const handleCancelFunction = () => {
        cancelFunction();
    }

    const handleCloseFunction = () => {
        closeFunction();
    }

    return (
        <div className="e-control e-toolbar e-lib e-keyboard e-toolpop" style={{ height: 'auto' }}>
            <div className="e-toolbar-items e-tbar-pos" style={{ height: '42px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ float: 'left'}}>
                        <div className="e-toolbar-item" >
                            <ButtonComponent
                                iconCss='e-icons e-save-changes'
                                className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                onClick={handleSaveFunction}
                                title="Save"
                                iconPosition='Left'>Save
                            </ButtonComponent>
                        </div>
                        {
                            showSaveAs &&
                            <div className="e-toolbar-item">
                                <ButtonComponent
                                    iconCss='e-icons e-save-changes'
                                    className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                    onClick={handleSaveAsFunction}
                                    title="Save As"
                                    iconPosition='Left'>Save As
                                </ButtonComponent>
                            </div>
                        }

                        <div className="e-toolbar-item">
                            <ButtonComponent
                                iconCss='e-icons e-cancel-action'
                                className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                iconPosition='Left'
                                title="Rotate Left"
                                onClick={handleCancelFunction}
                            >Cancel</ButtonComponent>
                        </div>

                    </div>

                    <div>
                        <div className="split-pages-title">
                            {fileName}
                        </div>
                     
                    </div>
                    <div style={{ float: 'right', display: 'flex' }}>
                        <div style={{ width: '150px' }}></div>
                        <div className="e-toolbar-item">
                            <ButtonComponent
                                iconCss='e-icons e-close-dialog'
                                className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib"
                                iconPosition='Left'
                                title="Rotate Left"
                                onClick={handleCloseFunction}
                            >Close</ButtonComponent>
                        </div>

                        <div style={{ width: '10px'}}></div>
                    </div>

                </div>

            </div>

        </div>
    )
}
