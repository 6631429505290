import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useRef } from 'react';
import { fileOpenDocumentEditorService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';

interface Props {
    filePath: string;
    fileName: string;
    fileProvider: string;
    refresh: any;
    fileSession: FileSession;
}

export default function FileManagerDocumentPreviewer({ fileName, filePath, fileProvider, refresh, fileSession }: Props) {
    const containerRef = useRef<DocumentEditorContainerComponent>(null);


    useEffect(() => {
        var fileExt = fileName.split('.').pop();
        if (fileExt?.toLowerCase() === "docx" || fileExt?.toLowerCase() === "txt") {
            const resizeDocumentEditor = () => {
                // Check if the containerRef is initialized and current is available
                if (containerRef && containerRef?.current) {
                    const containerElement = containerRef?.current?.element;
                    if (containerElement) {
                        const parentElement = containerElement?.parentElement;
                        if (parentElement) {
                            const width = parentElement.clientWidth;
                            const height = parentElement.clientHeight;
                            try {
                                // Check if resize and fitPage functions are available before calling them
                                if (containerRef?.current?.resize) {
                                    containerRef.current.resize(width, height);
                                }
                                if (containerRef?.current?.documentEditor?.fitPage) {
                                    containerRef.current.documentEditor.fitPage('FitPageWidth');
                                }
                            } catch (error) {
                                console.error('Error resizing the document editor:', error);
                            }
                        }
                    }
                }
            };

            // Resize once the component mounts
            resizeDocumentEditor();

            // Add the resize event listener only after the component has mounted
            const handleResize = () => {
                if (containerRef && containerRef?.current) {
                    resizeDocumentEditor();
                }
            };

            window?.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => {
                window?.removeEventListener('resize', handleResize);
            };
        }
    }, [refresh]);


    useEffect(() => {
        async function loadDocumentFromServer() {
            try {

                var fileExt = fileName.split('.').pop();
                if (fileExt?.toLowerCase() === "docx" || fileExt?.toLowerCase() === "txt") {
                    const result = await fileOpenDocumentEditorService(filePath, fileName, fileProvider, fileSession);
                    if (result) {
                        // get the data from the result
                        const documentData = result?.data;
                        if (documentData) {
                            if (containerRef && containerRef.current && containerRef.current.documentEditor) {
                                //JSON.stringify(documentData)
                                containerRef.current.documentEditor.open(documentData);
                                containerRef.current.documentEditor.documentName = fileName;
                                containerRef.current.documentChange = () => {
                                    containerRef?.current?.documentEditor.focusIn();
                                };
                            }
                        }
                    }
                }

            } catch (e) {
                console.log("Document Previewer : " + e);
            }
            finally {

            }
        }

        loadDocumentFromServer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileName]);

    return (
        <div style={{ height: '100%', width: '100%', overflowY: 'hidden' }}>
            {
                <DocumentEditorContainerComponent
                    id={`container_prev_${filePath}`}
                    autoResizeOnVisibilityChange={true}
                    readOnly={true}
                    ref={containerRef}
                    style={{ display: 'block' }}
                    height={'100vh'}
                    enableToolbar={false}
                    showPropertiesPane={false}
                    locale="en-US"
                />
            }
        </div>
    )
}
