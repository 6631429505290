/**
 * Represents a FileSession object with session information.
 *
 * @class FileProviderConnection
 */
export class FileProviderConnection {
    /**
     * The connection ID.
     * @type {string | undefined}
     */
    id;

    /**
     * The name of the connection.
     * @type {string | undefined}
     */
    name;

    /**
     * The path or link to the files.
     * @type {string | undefined}
     */
    path;

    /**
     * The B2C session information.
     * @type {string | undefined}
     */
    fileProviderType;

    /**
     * Creates a new FileProviderConnection object.
     *
     * @param {string | undefined} [Id] - The id of the connection.
     * @param {string | undefined} [name] - The name of the connection.
     * @param {string | undefined} [path] - The path or link.
     * @param {string | undefined} [fileProviderType] - The fileProviderType.
     */
    constructor(
        id?: string,
        name?: string,
        path?: string,
        fileProviderType?: string
    ) {
        this.id = id;
        this.name = name;
        this.path = path;
        this.fileProviderType = fileProviderType;
    }
}
